import { ThemeOptions } from "@mui/material/styles";

const components: ThemeOptions["components"] = {
  MuiButton: {
    styleOverrides: {
      root: {
        boxShadow: "none",
        textTransform: "none",
      },
      contained: {
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none",
        },
        "&:focus": {
          boxShadow: "none",
        },
      },
      outlined: {
        padding: "calc(0.75rem - 1px) calc(1.75rem - 1px)",
      },
      // TODO: class label no longer exists on MuiButton as the span that wraps button child elements has been removed, work out how to style the text
      // label: {
      //   fontSize: "0.875rem",
      //   "& .MuiButton-startIcon": {
      //     marginBottom: 1,
      //   },
      //   "& .MuiButton-endIcon": {
      //     marginBottom: 1,
      //   },
      // },
    },
  },
  MuiCardHeader: {
    defaultProps: {
      titleTypographyProps: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        component: "h4",
      },
    },
  },
  MuiCircularProgress: {
    styleOverrides: {
      colorPrimary: {
        color: "#FF844B",
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        backgroundColor: "#ffffff",
      },
      input: {
        paddingTop: "16px !important",
        paddingBottom: "16px !important",
      },
    },
  },
  MuiInputLabel: {
    variants: [
      {
        props: { margin: "dense" },
        style: {
          lineHeight: 0.5,
        },
      },
    ],
  },
  MuiList: {
    styleOverrides: {
      root: {
        backgroundColor: "#ffffff",
        borderRadius: 6,
      },
    },
  },
  MuiListItem: {
    styleOverrides: {
      root: {
        borderRadius: "22px !important",
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      notchedOutline: {
        borderColor: "#99A9BF",
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      rounded: {
        borderRadius: 20,
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        textTransform: "none",
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        fontSize: "0.875rem",
        fontWeight: 400,
      },
    },
  },
};

export default components;
